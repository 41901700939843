import React from 'react'
import Sorter from '../Table/Sorter'
import Th from '../Table/Th'
import THead from '../Table/THead'
import Tr from '../Table/Tr'

const DataTH = ({ sortable, headerGroups }) => {
    return (
        <THead>
            {sortable
                ? headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                              <Th
                                  {...column.getHeaderProps(
                                      column.getSortByToggleProps()
                                  )}
                              >
                                  {column.render('Header')}
                                  <span>
                                      <Sorter sort={column.isSortedDesc} />
                                  </span>
                              </Th>
                          ))}
                      </Tr>
                  ))
                : headerGroups.map((headerGroup) => (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                              <Th {...column.getHeaderProps()}>
                                  {column.render('Header')}
                              </Th>
                          ))}
                      </Tr>
                  ))}
        </THead>
    )
}

export default DataTH
