import React from 'react'
import Pagination from '../Pagination'
import Select from '../Select'

const pageSizeOption = [
    { value: 5, label: '5 / page' },
    { value: 10, label: '10 / page' },
    { value: 20, label: '20 / page' },
    { value: 30, label: '30 / page' },
    { value: 40, label: '40 / page' },
    { value: 50, label: '50 / page' },
]

const DataTablePagination = ({
    pageSize,
    pageIndex,
    dataLength,
    gotoPage,
    setPageSize,
}) => {
    const onPaginationChange = (page) => {
        gotoPage(page - 1)
    }

    const onSelectChange = (value) => {
        setPageSize(Number(value))
    }
    return (
        <div className="flex items-center justify-between mt-4">
            <Pagination
                pageSize={pageSize}
                currentPage={pageIndex + 1}
                total={dataLength}
                onChange={onPaginationChange}
            />
            <div style={{ minWidth: 130 }}>
                <Select
                    size="sm"
                    isSearchable={false}
                    value={pageSizeOption.filter(
                        (option) => option.value === pageSize
                    )}
                    options={pageSizeOption}
                    onChange={(option) => onSelectChange(option.value)}
                />
            </div>
        </div>
    )
}

export default DataTablePagination
