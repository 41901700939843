const appConfig = {
    apiPrefix: 'https://develop.api.diagnotrack.pro.alienyard.com',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'en',
    enableMock: false,
}

export default appConfig
